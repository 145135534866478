// Override default variables before the import
$background-bg: #272D2D
$primary: #23CE6B
$secondary: #F06543
$tertiary: #F6F8FF
$white: #FFFFFF

$border-radius: 1px


body
  margin: 0
  font-family: 'Lato', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  height: 100vh

a
  color: $white
  text-decoration: none

.header
  .logo
    font-weight: 900
    letter-spacing: 2px

.footer
  width: 100%
  position: fixed
  bottom: 0
  left: 0
  display: flex
  justify-content: center
  img
    max-width: 100%

.btn
  color: $white!important
  box-shadow: none!important
  text-transform: uppercase